// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";

.osn-time-format-cell {
    width: 110px;
}
.osn-feature-icons {
    justify-content: space-evenly;
    .osn-feat-icon {
        display: inline-block;
        position: relative;
        color: #c3c3c3;
        fill: #c3c3c3;
        justify-content: center;
        width: 20px;
        margin: 10px 3px 0 3px;
        &.osn-feat-icon-enable {
            color: #20a8d8;
            fill: #20a8d8;
        }
    }
}
